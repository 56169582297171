import { createContext } from 'react'

import usePersistedState from 'hooks/usePersistedState'
const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = usePersistedState('_idD0-theme', 'light')

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
