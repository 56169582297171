import { useEffect, useRef, useState } from 'react'

const usePersistedState = (name, defaultValue) => {
  const [value, setValue] = useState(defaultValue)
  const [isMounted, setIsMounted] = useState(false)
  const nameRef = useRef(name)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMounted(true)
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      const storedValue = localStorage.getItem(name)
      setValue(storedValue !== null ? JSON.parse(storedValue) : defaultValue)
    }
  }, [isMounted, defaultValue, name])

  useEffect(() => {
    const lastName = nameRef.current
    if (name !== lastName) {
      nameRef.current = name
    }
  }, [name])

  useEffect(() => {
    if (isMounted) {
      try {
        if (value === defaultValue) {
          localStorage.removeItem(nameRef.current)
        } else {
          localStorage.setItem(nameRef.current, JSON.stringify(value))
        }
      } catch {}
    }
  }, [isMounted, value, defaultValue])

  return [value, setValue]
}

export default usePersistedState
