const Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    data-tooltip="imprimir"
    {...props}
  >
    <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
      <path d="M2847,4972.3c-86.1-24.9-210.5-139.7-256.5-233.5c-36.4-76.6-40.2-116.7-40.2-507.2v-424.9h-756c-472.8,0-792.4-7.6-855.5-21c-386.6-80.4-736.9-430.6-817.3-817.3c-28.7-141.6-28.7-3043.2,0-3184.9c70.8-338.8,338.8-645,687.1-780.9l124.4-47.9h4067.2h4067.2l124.4,47.9c346.4,135.9,618.2,444,687.1,780.9c13.4,68.9,21,591.4,21,1598.2c0,1649.8,0,1630.7-120.6,1858.5c-78.5,151.2-294.8,367.5-445.9,445.9c-214.4,112.9-273.7,120.6-1184.8,120.6h-823l-447.9,557c-256.5,317.7-472.7,568.4-507.2,587.6c-53.6,30.6-202.9,32.5-1762.8,36.4C3650.9,4989.6,2879.6,4981.9,2847,4972.3z M6611.8,4046l434.5-535.9v-683.3l1.9-685.2H5000.2H2952.3V3355c0,666.1,5.7,1217.3,13.4,1224.9c7.7,5.7,731.1,9.6,1611.6,7.6l1600.1-5.7L6611.8,4046z M2550.4,2627.7v-777.1l65.1-65.1l65.1-65.1h2319.7H7320l65.1,65.1l65.1,65.1v779v777.1l790.5-5.8c911.1-7.6,907.2-7.6,1077.6-178c179.9-179.9,170.3-74.6,170.3-1856.6V-183.9L9433-304.5c-88-191.4-231.6-292.8-465.1-329.2c-204.8-30.6-7954.5-17.2-8048.3,15.3C810.6-582,670.8-478.6,615.3-394.4C510.1-241.3,512-266.2,512,1385.6c0,1425.9,1.9,1538.8,34.4,1630.7c65.1,185.7,233.5,327.3,436.4,367.5c45.9,9.6,417.2,17.2,826.8,19.1l740.7,1.9V2627.7z" />
      <path d="M8290.4,478.3c-44-44-55.5-72.7-55.5-135.9c0-44,9.6-95.7,19.1-116.7c28.7-53.6,120.6-93.8,212.5-93.8c63.2,0,90,11.5,143.5,65.1c45.9,45.9,65.1,82.3,65.1,124.4c0,137.8-84.2,212.4-239.2,212.4C8363.1,533.8,8336.3,524.3,8290.4,478.3z" />
      <path d="M2670.9-1562c-124.4-53.6-120.6,1.9-120.6-1506.3c0-1523.5-5.7-1443.1,135.9-1579c135.9-128.2-11.5-120.6,2317.8-120.6c2015.4,0,2114.9,1.9,2187.7,36.4c42.1,19.1,103.4,65.1,137.8,99.5c126.3,132.1,120.6,67,120.6,1571.4c0,1318.7-1.9,1366.6-38.3,1425.9c-47.8,78.5-151.2,111-237.3,74.6c-124.4-51.7-116.7,36.4-126.3-1445.1l-9.6-1351.3H5000.2H2961.9l-9.6,1351.3c-9.6,1485.3-1.9,1393.4-128.2,1447C2749.4-1527.5,2747.5-1527.5,2670.9-1562z" />
      <path d="M3478.6-2340.9c-70.8-38.3-105.3-99.5-105.3-183.7c0-67,11.5-93.8,55.5-139.7l55.5-55.5h1491c1381.9,0,1492.9,3.8,1529.2,34.5c105.3,84.2,112.9,208.6,19.1,302.4l-65.1,65.1H4988.7C3955.2-2319.9,3505.4-2325.6,3478.6-2340.9z" />
      <path d="M3428.9-3196.5c-91.9-93.8-65.1-271.8,49.8-323.5c36.4-17.2,419.2-23,1514-23h1466.1l65.1,65.1c45.9,45.9,65.1,82.3,65.1,124.4c0,74.6-45.9,166.5-93.8,193.3c-24.9,13.4-541.7,19.1-1523.5,19.1H3484.4L3428.9-3196.5z" />
    </g>
  </svg>
)

export default Icon
