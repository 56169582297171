import { useState } from 'react'

import { useRouter } from 'next/router'

import Eng from 'assets/icons/eng'
import Spa from 'assets/icons/spa'

const LangSelector = () => {
  const router = useRouter()
  const [lang, setLang] = useState(router.locale || 'es')

  const changeLang = (locale) => {
    setLang(locale)

    router.push(router.pathname, router.pathname, {
      locale
    })
  }

  return (
    <>
      <div className="icon">
        {lang === 'es' ? (
          <Eng onClick={() => changeLang('en')} />
        ) : (
          <Spa onClick={() => changeLang('es')} />
        )}
      </div>
      <style jsx>{`
        .icon {
          cursor: pointer;
        }
        .icon :global(svg) {
          background: red;
          border-radius: 50%;
          border: 2px solid var(--primary);
        }
      `}</style>
    </>
  )
}

export default LangSelector
