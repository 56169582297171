import { useContext } from 'react'

import { ThemeContext } from 'context'
import { useRouter } from 'next/router'

import Nav from 'components/nav'
import Transition from 'components/transition'

const Layout = ({ children }) => {
  const router = useRouter()
  const { theme } = useContext(ThemeContext)
  return (
    <>
      {(router.pathname !== '/bot' && (
        <div className={`layoutContainer ${theme}`}>
          <header>
            <Nav />
          </header>
          <Transition location={router.pathname}>
            <main className="content">{children}</main>
          </Transition>
        </div>
      )) || <main>{children}</main>}

      <style jsx>{`
        header {
          position: sticky;
          top: 0;
          z-index: 1;
          filter: opacity(0.9);
        }
        .layoutContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: auto;
          padding: 1rem;
          background-color: var(--white);
          min-height: 100vh;
        }
        .content {
          padding: 1rem;
          border-radius: 10px;
        }
        @media print {
          .layoutContainer {
            min-height: 0;
          }
        }
      `}</style>
    </>
  )
}
export default Layout
